<template>
    <FormFooter
        fixed
        offset="left"
        :offsetInPixels="screenSizeIsMobileLike || screenSizeIsTabletLike ? 0 : 248"
        class="form_footer"
        :class="{ 'form_footer--screen-size-is-mobile-like': screenSizeIsMobileLike }"
    >
        <div class="form_footer__controls_container">
            <slot></slot>
        </div>
    </FormFooter>
</template>

<script>
import { vueWindowSizeMixin } from "vue-window-size";
import FormFooter from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/FormFooter.vue";
import { useBreakPoints } from "@feedbackcompany/feedback-company-vue-components/src/util/useBreakPoints";

const { isMd, isBelow } = useBreakPoints();

export default {
    name: "SettingsFooter",
    mixins: [vueWindowSizeMixin],
    components: { FormFooter },
    computed: {
        screenSizeIsTabletLike() {
            return isMd(this.windowWidth);
        },
        screenSizeIsMobileLike() {
            return isBelow("md", this.windowWidth);
        },
    },
};
</script>

<style lang="scss" scoped>
.form_footer {
    padding: 0 24px;

    &.form_footer--screen-size-is-mobile-like {
        padding: 0 16px;
    }
}
.form_footer__controls_container {
    width: 100%;
    display: flex;
    max-width: 900px;
    justify-content: flex-end;
}
</style>
