var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GenericPage',{attrs:{"headerProps":{
        title: _vm.$t('ev__cp__scheduled_reports__title'),
        subtitle: _vm.$t('ev__cp__scheduled_reports__subtitle'),
        disableBackButton: true,
    }}},[_c('main',{staticClass:"scheduled_reports"},[_c('Table',{attrs:{"title":_vm.$t('ev__cp__scheduled_reports__table__title'),"columns":[
                {
                    label: _vm.$t('ev__cp__scheduled_reports__table__name_column__dashboard'),
                    property: 'dashboardView.name',
                },
                {
                    label: _vm.$t('ev__cp__scheduled_reports__table__name_column__name'),
                    property: 'name',
                },
                {
                    label: _vm.$t('ev__cp__scheduled_reports__table__name_column__recipients'),
                    property: 'recipientCount',
                },
                {
                    label: _vm.$t('ev__cp__scheduled_reports__table__name_column__frequency'),
                    property: 'frequency',
                },
                {
                    label: _vm.$t('ev__cp__scheduled_reports__table__name_column__start_date'),
                    property: 'startDate',
                },
                {
                    label: '',
                    property: 'actions',
                } ],"disableHeaderTextWrap":true,"sortable":"","empty":_vm.tableState.data.length === 0,"sortableColumns":[
                'dashboardView.name',
                'name',
                'recipientCount',
                'frequency',
                'startDate' ],"currentPage":_vm.tableState.currentPage,"currentSorted":_vm.tableState.currentSorted,"currentSortDirection":_vm.tableState.currentSortDirection,"searchable":_vm.tableState.searchEnabled,"searchableColumns":[
                'dashboardView.name',
                'name',
                'recipientCount',
                'frequency',
                'formattedStartDate' ],"searching":_vm.tableState.searchTerm !== null,"paginate":_vm.tableState.pages > 1 ||
                (_vm.tableState.currentPage === 1 && _vm.tableState.itemCount > 10),"defaultPaginationSize":_vm.tableState.paginationSize,"searchbarPlaceholder":_vm.$t('ev__cp__scheduled_reports__table__search_placeholder'),"pages":_vm.tableState.pages,"rowsPerPageText":_vm.$t('cp_table_rows_per_page')},on:{"sort":function($event){return _vm.sort($event)},"search":function($event){return _vm.search($event)},"pageChange":_vm.onPageChange,"onPaginationSizeChange":function($event){return _vm.setPaginationSize($event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.isLoading)?_c('div',{staticClass:"loading-indicator"},[_c('LoaderCircular',{attrs:{"height":24}})],1):(_vm.tableState.searchTerm !== null)?_c('span',{staticClass:"empty-table-message"},[_vm._v(" "+_vm._s(_vm.$t("ev__cp__scheduled_reports__table__pagination_no_search_results"))+" ")]):_c('span',{staticClass:"empty-table-message"},[_vm._v(" "+_vm._s(_vm.$t("ev__cp__scheduled_reports__table__pagination_no_scheduled_reports"))+" ")])]},proxy:true},{key:"pagination-indicator",fn:function(){return [_c('PaginationIndicator',{attrs:{"pages":_vm.tableState.pages,"currentPage":_vm.tableState.currentPage,"currentPageItemCount":_vm.tableState.data.length,"current-page-range-start":_vm.tableState.currentPageRangeStart,"current-page-range-end":_vm.tableState.currentPageRangeEnd,"itemCount":_vm.tableState.itemCount,"itemDescription":_vm.$t(
                            'ev__cp__scheduled_reports__table__pagination_indicator__description'
                        ),"ofString":_vm.$t('ev__cp__scheduled_reports__table__pagination_indicator__of'),"pageString":_vm.$t('ev__cp__scheduled_reports__table__pagination_indicator__page')}})]},proxy:true}])},_vm._l((_vm.tableState.data),function(row,index){return _c('TableRow',{key:index,attrs:{"isLast":_vm.tableState.data.length - 1 === index,"bordered":""}},[_c('TableCell',[_vm._v(_vm._s(row.dashboardView.name))]),_c('TableCell',[_vm._v(_vm._s(row.name))]),_c('TableCell',[_vm._v(_vm._s(row.recipientCount))]),_c('TableCell',[_vm._v(_vm._s(_vm.formatFrequency(row.frequency)))]),_c('TableCell',[_vm._v(_vm._s(row.formattedStartDate))]),_c('StickyTableCell',{attrs:{"compact":"","last":""}},[_c('div',{staticClass:"actions-wrapper"},[_c('IconButton',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                                content: _vm.$t('cp__reports_schedule_report_delete_tooltip'),
                            }),expression:"{\n                                content: $t('cp__reports_schedule_report_delete_tooltip'),\n                            }"}],attrs:{"label":""},nativeOn:{"click":function($event){return _vm.confirmDelete(row)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt']}})],1),_c('IconButton',{attrs:{"label":""}},[_c('router-link',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                                    content: _vm.$t('cp__reports_schedule_report_edit_tooltip'),
                                }),expression:"{\n                                    content: $t('cp__reports_schedule_report_edit_tooltip'),\n                                }"}],attrs:{"to":("/schedule-report/" + (row.id))}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'edit']}})],1)],1)],1)])],1)}),1)],1),_c('SettingsFooter',[_c('Button',{nativeOn:{"click":function($event){return _vm.scheduleReport.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("ev__cp__scheduled_reports__table__schedule_new_report"))+" ")])],1),(_vm.showConfirmPopup)?_c('ConfirmPopup',{attrs:{"title":_vm.$t('cp__generic__warning'),"message":_vm.$t('cp__reports_schedule_report_delete_message'),"confirm-btn":_vm.$t('cp__generic__delete'),"confirmButtonStyle":"negative","confirmBtnIsLoading":_vm.deleteInProgress},on:{"confirm":_vm.deleteScheduledReport,"cancel":_vm.closeConfirmDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }