<template>
    <GenericPage
        :headerProps="{
            title: $t('ev__cp__scheduled_reports__title'),
            subtitle: $t('ev__cp__scheduled_reports__subtitle'),
            disableBackButton: true,
        }"
    >
        <main class="scheduled_reports">
            <Table
                :title="$t('ev__cp__scheduled_reports__table__title')"
                :columns="[
                    {
                        label: $t('ev__cp__scheduled_reports__table__name_column__dashboard'),
                        property: 'dashboardView.name',
                    },
                    {
                        label: $t('ev__cp__scheduled_reports__table__name_column__name'),
                        property: 'name',
                    },
                    {
                        label: $t('ev__cp__scheduled_reports__table__name_column__recipients'),
                        property: 'recipientCount',
                    },
                    {
                        label: $t('ev__cp__scheduled_reports__table__name_column__frequency'),
                        property: 'frequency',
                    },
                    {
                        label: $t('ev__cp__scheduled_reports__table__name_column__start_date'),
                        property: 'startDate',
                    },
                    {
                        label: '',
                        property: 'actions',
                    },
                ]"
                :disableHeaderTextWrap="true"
                sortable
                :empty="tableState.data.length === 0"
                :sortableColumns="[
                    'dashboardView.name',
                    'name',
                    'recipientCount',
                    'frequency',
                    'startDate',
                ]"
                :currentPage="tableState.currentPage"
                :currentSorted="tableState.currentSorted"
                :currentSortDirection="tableState.currentSortDirection"
                :searchable="tableState.searchEnabled"
                :searchableColumns="[
                    'dashboardView.name',
                    'name',
                    'recipientCount',
                    'frequency',
                    'formattedStartDate',
                ]"
                :searching="tableState.searchTerm !== null"
                :paginate="
                    tableState.pages > 1 ||
                    (tableState.currentPage === 1 && tableState.itemCount > 10)
                "
                :defaultPaginationSize="tableState.paginationSize"
                :searchbarPlaceholder="$t('ev__cp__scheduled_reports__table__search_placeholder')"
                :pages="tableState.pages"
                :rowsPerPageText="$t('cp_table_rows_per_page')"
                @sort="sort($event)"
                @search="search($event)"
                @pageChange="onPageChange"
                @onPaginationSizeChange="setPaginationSize($event)"
            >
                <TableRow
                    v-for="(row, index) in tableState.data"
                    v-bind:key="index"
                    v-bind:isLast="tableState.data.length - 1 === index"
                    bordered
                >
                    <TableCell>{{ row.dashboardView.name }}</TableCell>
                    <TableCell>{{ row.name }}</TableCell>
                    <TableCell>{{ row.recipientCount }}</TableCell>
                    <TableCell>{{ formatFrequency(row.frequency) }}</TableCell>
                    <TableCell>{{ row.formattedStartDate }}</TableCell>
                    <StickyTableCell compact last>
                        <div class="actions-wrapper">
                            <IconButton
                                label=""
                                v-tippy="{
                                    content: $t('cp__reports_schedule_report_delete_tooltip'),
                                }"
                                @click.native="confirmDelete(row)"
                            >
                                <font-awesome-icon :icon="['far', 'trash-alt']" />
                            </IconButton>

                            <IconButton label="">
                                <router-link
                                    :to="`/schedule-report/${row.id}`"
                                    v-tippy="{
                                        content: $t('cp__reports_schedule_report_edit_tooltip'),
                                    }"
                                >
                                    <font-awesome-icon :icon="['far', 'edit']" />
                                </router-link>
                            </IconButton>
                        </div>
                    </StickyTableCell>
                </TableRow>
                <template v-slot:empty>
                    <div v-if="isLoading" class="loading-indicator">
                        <LoaderCircular :height="24" />
                    </div>
                    <span v-else-if="tableState.searchTerm !== null" class="empty-table-message">
                        {{ $t("ev__cp__scheduled_reports__table__pagination_no_search_results") }}
                    </span>
                    <span v-else class="empty-table-message">
                        {{
                            $t("ev__cp__scheduled_reports__table__pagination_no_scheduled_reports")
                        }}
                    </span>
                </template>
                <template v-slot:pagination-indicator>
                    <PaginationIndicator
                        :pages="tableState.pages"
                        :currentPage="tableState.currentPage"
                        :currentPageItemCount="tableState.data.length"
                        :current-page-range-start="tableState.currentPageRangeStart"
                        :current-page-range-end="tableState.currentPageRangeEnd"
                        :itemCount="tableState.itemCount"
                        :itemDescription="
                            $t(
                                'ev__cp__scheduled_reports__table__pagination_indicator__description'
                            )
                        "
                        :ofString="$t('ev__cp__scheduled_reports__table__pagination_indicator__of')"
                        :pageString="
                            $t('ev__cp__scheduled_reports__table__pagination_indicator__page')
                        "
                    />
                </template>
            </Table>
        </main>
        <SettingsFooter>
            <Button @click.native="scheduleReport">
                {{ $t("ev__cp__scheduled_reports__table__schedule_new_report") }}
            </Button>
        </SettingsFooter>
        <ConfirmPopup
            v-if="showConfirmPopup"
            :title="$t('cp__generic__warning')"
            :message="$t('cp__reports_schedule_report_delete_message')"
            :confirm-btn="$t('cp__generic__delete')"
            confirmButtonStyle="negative"
            @confirm="deleteScheduledReport"
            @cancel="closeConfirmDialog"
            :confirmBtnIsLoading="deleteInProgress"
        />
    </GenericPage>
</template>

<script>
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import IconButton from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/IconButton.vue";
import PaginationIndicator from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/PaginationIndicator.vue";
import StickyTableCell from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/StickyTableCell.vue";
import TableCell from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/TableCell.vue";
import TableRow from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/TableRow.vue";
import Table from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Table.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import { useTableState } from "@/composables/useTableState";
import { faEdit, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { format } from "date-fns";
import cloneDeep from "lodash.clonedeep";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import GenericPage from "../components/Global/Molecules/GenericPage.vue";
import getScheduledReports from "../graphql/getScheduledReports.gql";
import deleteScheduledReport from "../graphql/reports/deleteScheduledReport.gql";

library.add(faTrashAlt, faEdit);

export default {
    name: "ScheduledReports",
    components: {
        TableRow,
        Table,
        TableCell,
        GenericPage,
        PaginationIndicator,
        LoaderCircular,
        FontAwesomeIcon,
        IconButton,
        SettingsFooter,
        Button,
        ConfirmPopup,
        StickyTableCell,
    },
    data() {
        return {
            showConfirmPopup: false,
            deletionReport: null,
            deleteInProgress: false,
        };
    },
    setup() {
        const isLoading = true;

        // General table state
        const { data, setData, sort, search, searchTerm, setPage, setPaginationSize } =
            useTableState();

        return {
            isLoading,
            tableState: data,
            search,
            searchTerm,
            sort,
            setPage,
            setData,
            setPaginationSize,
        };
    },
    methods: {
        onPageChange(page) {
            this.setPage(page);
        },
        formatFrequency(frequency) {
            if (!frequency) return "";
            frequency = frequency.toLowerCase();
            return frequency.charAt(0).toUpperCase() + frequency.slice(1);
        },
        formatDate(date) {
            if (!date) return "";
            return format(new Date(date), "dd-MM-yyyy");
        },
        scheduleReport() {
            this.$router.push("/schedule-report");
        },
        confirmDelete(report) {
            this.showConfirmPopup = true;
            this.deletionReport = report;
        },
        closeConfirmDialog() {
            this.showConfirmPopup = false;
            this.deleteInProgress = false;
            this.deletionReport = null;
        },
        async deleteScheduledReport() {
            try {
                this.deleteInProgress = true;
                await this.$apollo.mutate({
                    mutation: deleteScheduledReport,
                    variables: {
                        reportId: this.deletionReport.id,
                    },
                });
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__generic__success"),
                    message: this.$t("cp__reports_schedule_report_delete_success_message"),
                });
                this.getScheduledReports();
            } catch (error) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__reports_schedule_report_delete_error_message"),
                });
            }
            this.closeConfirmDialog();
        },
        async getScheduledReports() {
            const queryResult = await this.$apollo.query({
                query: getScheduledReports,
                fetchPolicy: "network-only",
            });
            const data = cloneDeep(queryResult.data.scheduledReports);
            data.forEach((report) => {
                report.formattedStartDate = this.formatDate(report.startDate);
            });
            this.setData(data);
        },
    },
    async created() {
        await this.getScheduledReports();
        this.isLoading = false;
    },
};
</script>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/colors";
@import "~include-media";

.scheduled_reports {
    width: 100%;
    margin: 40px 0 140px 0;
    max-width: 950px;

    ::v-deep {
        .table__container {
            @include media("<tablet") {
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar:vertical {
                    width: 12px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 10px;
                    border: 2px solid #ffffff;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #ffffff;
                }
            }
        }
    }
}
.filename__tablecell {
    width: 100%;
}
.empty-table-message {
    padding: 10px 24px;
}

.loading-indicator {
    width: 100%;
    display: flex;
    justify-content: center;
}

.actions-wrapper {
    display: flex;
    justify-content: space-between;

    @include media("<tablet") {
        min-width: 60px;
    }

    .fa-edit {
        color: $blue;
        &:hover {
            color: $blue_sapphire;
        }
    }
    .fa-trash-alt {
        color: $blue;
        &:hover {
            color: $red;
        }
    }
}
</style>
